.input-range-container {
  padding: 3px 10px 8px 5px;
}

.input-range {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 8px;
  background: #cccccc;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.input-range:hover {
  opacity: 1;
}

.input-range::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0065FF;
  cursor: pointer;
}

.input-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0065FF;
  cursor: pointer;
}

.input-range-value {
  background-color: #373737;
  color: #dfdfdf;
  padding: 2px 4px;
  outline: 0;
  border-radius: 4px;
  font-size: 16px;
}