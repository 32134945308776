html,
body,
#root,
.app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Tahoma, Geneva, sans-serif;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

textarea {
  resize: vertical;
}

/* Toastify */
.Toastify__toast-body {
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 260px;
}

/* Echarts */
.echarts {
  width: 100% !important;
  height: 100% !important;
}

/* Utility */
.display-flex {
  display: flex;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.inline-block {
  display: inline-block;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiline {
  word-wrap: break-word;
  overflow: hidden;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.word-break-all {
  word-break: break-all;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 8px 0;
  padding: 0;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border: 0px none transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

::-webkit-scrollbar-thumb:active {
  background: #bdbdbd;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none transparent;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}