.report-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 249px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  background-color: #efefef;
}

.report-content {
  position: absolute;
  top: 0;
  left: 250px;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  overflow: auto;
}

.report-menu-panel {
  padding: 5px 10px;
  border: 1px solid #f1e7e7;
}

.report-menu-item {
  border-left: 3px solid #EBECF0;
  margin: 3px 0px;
  padding: 3px 6px;
  color: #8993A4;
  font-size: 13px;
  cursor: pointer;
}

.report-menu-item:hover {
  border-left: 3px solid #1D0016;
}

.report-menu-item-active {
  border-left: 3px solid #cf1b18;
  color: #1D0016;
  font-weight: 700;
}

.report-content-component-panel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  padding: 0px 10px 10px 20px;
}

.report-drillthrough-name {
  padding: 6px 0px 6px 14px;
  font-size: 20px;
}

.report-name {
  padding: 6px 14px;
  font-size: 20px;
}

.report-name-input {
  margin-left: 10px;
}

.report-edit-component-dialog {
  left: 50%;
  width: 800px;
  top: 20px;
  bottom: 20px;
  margin-left: -400px;
}

.report-edit-filter-dialog {
  left: 50%;
  width: 1000px;
  top: 20px;
  bottom: 20px;
  margin-left: -500px;
}

.empty-report {
  margin-top: 20px;
  text-align: center;
  color: #1D0016;
  font-size: 20px;
  font-weight: 700;
}

.report-side-panel {
  position: fixed;
  top: 40px;
  left: 0px;
  bottom: 0px;
  width: 249px;
  border: 0;
  outline: 0;
  overflow-y: auto;
  background-color: #4B4B4B;
  color: #DFDFDF;
  z-index: 60;
}

.project-row {
  margin: 5px 0px;
  padding: 3px 0px;
  cursor: pointer;
}

.exporting-panel {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 90px;
  margin-top: -45px;
  margin-left: -80px;
  text-align: center;
}

.exporting-panel-title {
  font-size: 22px;
  font-weight: 700;
  margin: 0px 0px 15px 0px;
  color: #FFFFFF;
}

.exporting-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
}