.schema-side-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  width: 449px;
  min-width: 249px;
  overflow-y: auto;
  border-left: 1px solid #ccc;
  background-color: #efefef;
  padding: 5px;
}

.schema-title-row {
  padding: 4px 8px;
  cursor: pointer;
  margin-bottom: 3px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.schema-column-row-type {
  color: #8993A4;
}