.search-input-container {
  display: flex;
  flex-direction: row;
  width: inherit;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.search-input-icon {
  width: 32px;
  height: 31px;
  background-color: #FFFFFF;
  border-radius: 4px 0px 0px 4px;
}

.search-input-input {
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: calc(100% - 64px);
  margin: 0px !important;
  padding: 5px 0px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 2px solid #FFFFFF;
  outline: 0;
  background: #FFFFFF;
  font-size: 16px;
  height: 31px;
}

.search-input-input:focus {
  border: 2px solid #FFFFFF !important;
}

.search-input-reset-button {
  border-radius: 0px 4px 4px 0px;
  width: 32px;
  height: 31px;
  box-shadow: none;
  background-color: #FFFFFF;
  color: #212121;
  border: 0;
}

.search-input-reset-button:hover {
  background-color: #FFFFFF;
  color: #212121;
}