.colorpicker-select {
  background-color: #373737;
  border: 1px solid #00001c;
  display: inline-block;
  border-radius: 4px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.colorpicker-color {
  width: 36px;
  height: 20px;
  border-radius: 4px;
}

.colorpicker-popover {
  position: fixed;
  z-index: 90;
}

.colorpicker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 89;
}