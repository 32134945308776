.image-original-scale {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.image-full {
  width: 100%;
  height: 100%; 
  display: block;
}