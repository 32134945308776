.selectbuttons-container {
  display: flex;
  flex-direction: row;
  width: inherit;
  box-sizing: border-box;
}

.selectbuttons-container .select-button {
  margin-right: 5px;
  min-width: 100px;
  text-align: center;
  border-radius: 4px;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 0;
  outline: none;
  color: #8993A4;
  flex-grow: 1;
}

.selectbuttons-container .select-button:hover {
  background-color: #C1C7D0;
}

.selectbuttons-container .select-button-active {
  background-color: #212121;
  color: white;
}