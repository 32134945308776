.checkbox-container {
  display: flex;
  flex-flow: row;
  height: 20px;
}

.checkbox-checkmark {
  width: 20px;
  height: 20px;
  flex: 0 1 auto;
  cursor: pointer;
}

.checkbox-label {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}