.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}