.tab-header {
  list-style-type: none;
  border-bottom: 1px solid #ccc;
  padding: 0px;
  margin: 0px;
}

.tab-header-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 6px 12px;
  cursor: pointer;
  color: #8993A4;
}

.tab-header-active {
  border-bottom: 3px solid #cf1b18;
  color: #212121;
}

.tab-content {}