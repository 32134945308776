.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
}

.model-title {
  font-size: 18px;
  float: left;
}

.model-close-button {
  color: #212121;
  float: right;
  cursor: pointer;
}

.model-close-button:hover {
  background-color: #212121;
  color: #FFFFFF;
}

.modal-panel {
  background-color: #efefef;
  position: fixed;
  overflow-y: auto;
  padding: 10px;
  z-index: 200;
  max-height: calc(100vh - 60px);
}