.workspace-name {
  display: block;
  color: white;
  padding: 7px 16px;
  font-size: 22px;
  font-weight: 700;
  float: left;
  margin-right: 6px;
}

.workspace-nav {
  width: 100%;
  height: 40px;
  background-color: #1D0016;
  box-sizing: border-box;
}

.workspace-nav-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  float: left;
}

.workspace-nav-menu-text {
  margin-left: 6px;
}

.workspace-nav-menu-item {
  display: inline-block;
  list-style: none;
  font-size: 16px;
}

.workspace-nav-menu-item-value {
  display: block;
  color: #FFFFFF;
  padding: 11px 9px 10px 9px;
  cursor: pointer;
}

.workspace-nav-menu-item-dropdown {
  position: absolute;
  z-index: 1;
  background-color: #212121;
  min-width: 100px;
}

.workspace-nav-menu-item-value:hover {
  background-color: #b33e3c;
  color: #FFFFFF !important;
}

.menu-item-active {
  background-color: #cf1b18;
  color: #FFFFFF !important;
}

.workspace-content {
  position: fixed;
  top: 40px;
  left: 0px;
  right: 0;
  bottom: 0;
  background-color: #efefef;
  overflow-x: hidden;
  overflow-y: auto;
}

.workspace-account-menu {
  float: right;
}

.workspace-account-button {
  display: inline-block;
  color: #FFFFFF;
  padding: 11px 10px 10px 10px;
  text-decoration: none;
  cursor: pointer;
}

.workspace-account-button:hover {
  background-color: #cf1b18;
  color: #FFFFFF !important;
}

.workspace-account-dropdown {
  position: absolute;
  right: 0px;
  z-index: 1;
  background-color: #212121;
  width: 150px;
}

.workspace-dropdown-button {
  color: #FFFFFF;
  cursor: pointer;
  padding: 8px 12px;
}

.workspace-dropdown-button:hover {
  background-color: #cf1b18;
  color: #FFFFFF !important;
}