.login-view {
  min-height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  min-width: 100%;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
}

.login-input {
  padding: 7px 10px !important;
  border: 2px solid #FFFFFF !important;
}

.login-input:focus {
  border: 2px solid #bdbdbd !important;
}

.login-panel {
  padding: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-panel-body {
  background-color: #eeeeee;
  border-radius: 5px;
}

.login-app-title {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 25px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #1D0016;
  padding: 8px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.login-button {
  width: 100%;
}

.login-error-msg {
  text-align: center;
  margin-bottom: 5px;
  color: #ef5350;
  font-weight: bold;
}

.version-number {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
}