.slicer-body {
  padding-top: 5px;
}

.slicer-toggle-button {
  margin-top: 5px;
  border-radius: 4px;
  border: 0;
  height: auto;
  width: auto;
  line-height: 20px;
  outline: none;
  cursor: pointer;
  display: flex;
}