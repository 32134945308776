/** ReactTable */
.ReactTable {
  font-size: 15px !important;
}

.ReactTable .rt-table {
  background-color: #FFFFFF;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: 1px solid #B3BAC5;
}

.ReactTable .rt-tbody .rt-td {
  padding: 5px 3px;
}

.ReactTable .-pagination {
  width: 400px !important;
  box-shadow: none !important;
  border-top: none !important;
  float: right;
  margin-right: 5px;
  font-size: 12px !important;
  padding: 0px !important;
}


.ReactTable .pagination-bottom {
  border-top: 1px solid #B3BAC5;
  background-color: #FFFFFF;
  height: 35px;
}

.ReactTable .-pagination .-previous {
  width: 60px !important;
  flex: none !important;
  height: 26px;
  margin-top: 4px;
}

.ReactTable .-pagination .-center {
  width: 270px !important;
  flex: none !important;
}

.ReactTable .-pagination .-next {
  width: 60px !important;
  flex: none !important;
  height: 26px;
  margin-top: 4px;
}

.ReactTable .-pagination .-pageInfo {
  margin: 3px 0px 3px 0px !important;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 0px 3px 0px !important;
}

.ReactTable .-pagination .-btn {
  border: 0;
  outline: none;
  cursor: pointer;
  color: #000000 !important;
  background-color: #FFFFFF !important;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: solid 1px rgba(0,0,0,0.05) !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc, 
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #0065FF;
}

.ReactTable .rt-thead .rt-th.-sort-desc, 
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #0065FF;
}

.ReactTable .rt-resizer {
  z-index: auto !important;
}