.studio-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #FFFFFF;
}

.studio-menu-sidebar {
  width: 199px;
  min-width: 199px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  background-color: #efefef;
}

.studio-property-sidebar {
  width: 199px;
  min-width: 199px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  background-color: #efefef;
}

.studio-property-panel {
  border-bottom: 1px solid #ccc;
  background-color: #FFFFFF;
  padding: 5px 8px 0px 8px;
}

.studio-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.studio-editor-container {
  height: 320px;
}

.studio-result-control-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px 8px;
  display: flex;
  justify-content: space-between;
}

.studio-result-container {
  overflow-y: auto;
}

.studio-datasource-container {
  padding: 5px 8px;
  border-bottom: 1px solid #ccc;
  display: flex;
}

.studio-datasource-select {
  width: 300px;
}

.studio-query-menu-item {
  border-left: 3px solid #EBECF0;
  margin: 5px 0px;
  padding: 3px 3px 3px 8px;
  font-weight: 400;
  color: #8993A4;
  cursor: pointer;
}

.studio-query-menu-item:hover {
  border-left: 3px solid #cf1b18;
}

.studio-query-menu-item-active {
  border-left: 3px solid #cf1b18;
  color: #cf1b18;
}