.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
}

.dropdown-panel {
  position: fixed;
  z-index: 151;
  border-radius: 4px;
  top: 85px;
  right: 35px;
  background-color: #FFFFFF;
  border: 1px solid #e0e0e0;
}

.dropdown-body {
  padding: 8px;
}